<!--
 * @Author: zzh
 * @Date: 2022-02-25 09:55:19
 * @LastEditors: zzh
 * @LastEditTime: 2022-11-30 16:06:30
 * @Description: 侧边栏logo(需要跟随侧边栏折叠)
 * @FilePath: \hwason-laboratory-systems\src\layout\horizontalLayout\components\navigationBar\SidebarLogo.vue
-->
<template>
  <router-link key="expand" :to="allMenuList && allMenuList.length > 1 ? '/dashboard' : ''" class="root-link">
    <img src="@/assets/img/logo1.png" class="logo">
  </router-link>
</template>

<script setup lang="ts">
import { useLayoutStore } from '@/stores/layout';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
const store = useLayoutStore();

const { allMenuList } = storeToRefs(store);

onMounted(() => {
  console.log();
});
</script>

<style lang="scss" scoped>
.root-link {
  height: 60px;
  line-height: 60px;
  vertical-align: middle;

  .logo {
    width: 100%;
    height: 100%;
  }
}
</style>
