/*
 * @Author: zzh
 * @Date: 2022-03-07 13:39:30
 * @LastEditors: zzh
 * @LastEditTime: 2023-03-08 01:02:55
 * @Description: 基于Element UI Message的二次封装
 * @FilePath: \hwason-laboratory-systems\src\components\hs-message.ts
 */
import { ElMessage, ElMessageBox } from 'element-plus';

const popSuccessMessage = (message: string, duration = 2000) => {
    ElMessage({
        message,
        grouping: true,
        type: 'success',
        duration,
        showClose: true,
      });
};

const popErrorMessage = (message: string, duration = 5000) => {
    ElMessage({
        message,
        grouping: true,
        type: 'error',
        duration,
        showClose: true,
      });
};

const popWarningMessage = (message: string, duration = 5000) => {
  ElMessage({
      message,
      grouping: true,
      type: 'warning',
      duration,
      showClose: true,
    });
};

const popInfoMessage = (message: string, duration = 5000) => {
    ElMessage({
        message,
        grouping: true,
        type: 'info',
        duration,
        showClose: true,
      });
};

const isMessageConfirm = async (message: string, title: string, dangerouslyUseHTMLString = false) => {
    try {
        const result = await ElMessageBox.confirm(message, title, { closeOnClickModal: false, dangerouslyUseHTMLString: dangerouslyUseHTMLString, });
        return result === 'confirm';
    } catch(error) {
        console.info('cancel');
        return false;
    }

};

const popConfirmMessage = async (message: string, title: string,) => {
    const result = await ElMessageBox.confirm(message, title,
        {
          confirmButtonText: '确认',
        //   cancelButtonText: 'Cancel',
          showCancelButton:false,
          type: 'warning',
        }
      );
};

export 
{
    popSuccessMessage,
    popErrorMessage,
    popWarningMessage,
    popInfoMessage,
    isMessageConfirm,
    popConfirmMessage
};