/*
 * @Author: zzh
 * @Date: 2022-03-03 10:26:35
 * @LastEditors: zzh
 * @LastEditTime: 2022-05-05 13:20:01
 * @Description: 公共系统路由
 * @FilePath: \hwason-laboratory-systems\src\router\publicSystems.ts
 */
import { RouteRecordRaw } from 'vue-router';
// import VerticalLayout from '@/layout/verticalLayout/Index.vue';
import HorizontalLayout from '@/layout/horizontalLayout/Index.vue';

const publicSystemsRouter: Array<RouteRecordRaw> = [
  {
    path: '/publicSystems',
    component: HorizontalLayout,
    redirect: '/publicSystems/dashboard',
    name: 'publicSystems',
    meta: {
      title: '公共系统',
      affix: true
    },
    children: [
      {
        path: 'dashboard',
        component: () =>
          import('@publicSystems/views/dashboard/Index.vue'),
        name: 'publicSystemsDashboard',
        meta: {
          title: '公共系统首页',
          affix: true
        }
      },
      //#region 权限管理
      {
        path: '/publicSystems/authorityManagement/userManagement',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_userManagement" */ '@publicSystems/views/authorityManagement/userManagement.vue'
          ),
        name: 'publicSystemsUserManagement',
        meta: {
          title: '用户管理',
          affix: true
        }
      },
      {
        path: '/publicSystems/authorityManagement/roleManagement',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_roleManagement" */ '@publicSystems/views/authorityManagement/roleManagement.vue'
          ),
        name: 'publicSystemsRoleManagement',
        meta: {
          title: '角色管理',
          affix: true
        }
      },
      // {
      //   path: '/publicSystems/authorityManagement/roleManagement',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "publicSystems_roleManagement" */ '@publicSystems/views/authorityManagement/roleManagement.vue'
      //     ),
      //   name: 'publicSystemsRoleManagement',
      //   meta: {
      //     title: '角色管理',
      //   }
      // },
      {
        path: '/publicSystems/authorityManagement/organizationalStructure',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_organizationalStructure" */ '@publicSystems/views/authorityManagement/organizationalStructure.vue'
          ),
        name: 'publicSystemsOrganizationalStructure',
        meta: {
          title: '组织管理 ',
        }
      },
      {
        path: '/publicSystems/authorityManagement/menuManagement',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_menuManagement" */ '@publicSystems/views/authorityManagement/menuManagement/Index.vue'
          ),
        name: 'publicSystemsMenuManagement',
        meta: {
          title: '菜单管理 ',
        }
      },
      //#endregion

      //#region 报告项目管理
      {
        path: '/publicSystems/reportProjectManagement/reportItem',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_reportItem" */ '@publicSystems/views/reportProjectManagement/reportItem.vue'
          ),
        name: 'publicSystemsReportItem',
        meta: {
          title: '报告项目',
        }
      },
      {
        path: '/publicSystems/reportProjectManagement/reportItemClassification',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_reportItem" */ '@publicSystems/views/reportProjectManagement/reportItemClassification.vue'
          ),
        name: 'reportItemClassification',
        meta: {
          title: '报告项目归类',
        }
      },
      //#endregion

      //#region 报告单元管理
      {
        path: '/publicSystems/reportUnitManagement/reportUnit',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_reportItem" */ '@publicSystems/views/reportUnitManagement/reportUnit.vue'
          ),
        name: 'reportUnit',
        meta: {
          title: '报告单元',
        }
      },
      {
        path: '/publicSystems/reportUnitManagement/reportUnitCompareProject',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_reportItem" */ '@publicSystems/views/reportUnitManagement/reportUnitCompareProject.vue'
          ),
        name: 'reportUnitCompareProject',
        meta: {
          title: '报告单元对照项目',
        }
      },
      {
        path: '/publicSystems/reportUnitManagement/reportUnitCompareApplyProject',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_reportItem" */ '@publicSystems/views/reportUnitManagement/reportUnitCompareApplyProject.vue'
          ),
        name: 'reportUnitCompareApplyProject',
        meta: {
          title: '报告单元对照申请项目',
        }
      },
      {
        path: '/publicSystems/reportUnitManagement/reportUnitCombinationReviewCriteria',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_reportItem" */ '@publicSystems/views/reportUnitManagement/reportUnitCombinationReviewCriteria.vue'
          ),
        name: 'reportUnitCombinationReviewCriteria',
        meta: {
          title: '报告单元组合审核条件',
        }
      },
      {
        path: '/publicSystems/reportUnitManagement/reportUnitOptionSettings',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_reportItem" */ '@publicSystems/views/reportUnitManagement/reportUnitOptionSettings.vue'
          ),
        name: 'reportUnitOptionSettings',
        meta: {
          title: '报告单元选项设置',
        }
      },
      {
        path: '/publicSystems/reportUnitManagement/reportUnitRightClickMaintenance',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_reportItem" */ '@publicSystems/views/reportUnitManagement/reportUnitRightClickMaintenance.vue'
          ),
        name: 'reportUnitRightClickMaintenance',
        meta: {
          title: '报告单元右键单维护',
        }
      },
      //#endregion

      //#region 基础设置2
      {
        path: '/publicSystems/basicSettings/dictionaryClassification',
        component: () =>
          import(
            '@publicSystems/views/basicSettings/dictionaryClassification.vue'
          ),
        name: 'dictionaryClassification',
        meta: {
          title: '字典分类',
        }
      },
      {
        path: '/publicSystems/basicSettings/dictionaryData',
        component: () =>
          import(
            '@publicSystems/views/basicSettings/dictionaryData.vue'
          ),
        name: 'dictionaryData',
        meta: {
          title: '字典数据',
        }
      },
      // {
      //   path: '/publicSystems/basicSettings/singleBarcodePrefabrication',
      //   component: () => import('@publicSystems/views/basicSettings/singleBarcodePrefabrication.vue'),
      //   name: 'publicSystemsSingleBarcodePrefabrication',
      //   meta: {
      //     title: '单条码预制',
      //   }
      // },
      // {
      //   path: '/publicSystems/basicSettings/barcodeManagement',
      //   component: () => import('@publicSystems/views/basicSettings/barcodeManagement.vue'),
      //   name: 'publicSystemsBarcodeManagement',
      //   meta: {
      //     title: '条码管理',
      //   }
      // },
      //#endregion

      //#region 客户项目管理4
      {
        path: '/publicSystems/customerProjectManagement/customerManagement',
        component: () => import('@publicSystems/views/customerProjectManagement/customerManagement.vue'),
        name: 'customerManagement',
        meta: {
          title: '客户管理',
        }
      },
      {
        path: '/publicSystems/customerProjectManagement/clientApplicationProject',
        component: () => import('@publicSystems/views/customerProjectManagement/clientApplicationProject.vue'),
        name: 'clientApplicationProject',
        meta: {
          title: '客户申请项目',
        }
      },
      {
        path: '/publicSystems/customerProjectManagement/clientApplicationProjectComparison',
        component: () => import('@publicSystems/views/customerProjectManagement/clientApplicationProjectComparison.vue'),
        name: 'clientApplicationProjectComparison',
        meta: {
          title: '客户申请项目对照',
        }
      },
      {
        path: '/publicSystems/customerProjectManagement/customerApplicationInquiry',
        component: () => import('@publicSystems/views/customerProjectManagement/customerApplicationInquiry.vue'),
        name: 'customerApplicationInquiry',
        meta: {
          keepAlive: true,
          title: '客户申请单查询',
        }
      },
      //#endregion

      //#region 报告单管理
      {
        path: '/publicSystems/reportSheetManagement/reportFormMaintenance',
        component: () =>
          import(
            /* webpackChunkName: "publicSystems_reportItem" */ '@publicSystems/views/reportSheetManagement/reportFormMaintenance/Index.vue'
          ),
        name: 'reportFormMaintenance',
        meta: {
          title: '报告单维护',
        }
      },
      {
        path: '/publicSystems/reportSheetManagement/reportFormTemplateSettings',
        component: () =>
          import(
            '@publicSystems/views/reportSheetManagement/reportFormTemplateSettings/index.vue'
          ),
        name: 'reportFormTemplateSettings',
        meta: {
          title: '报告单模板设置',
        }
      },
      {
        path: '/publicSystems/reportSheetManagement/batchInputTemplateMaintenance',
        component: () =>
          import(
            '@publicSystems/views/reportSheetManagement/batchInputTemplateMaintenance/index.vue'
          ),
        name: 'batchInputTemplateMaintenance',
        meta: {
          title: '批次输入模板维护',
        }
      },
      {
        path: '/publicSystems/reportSheetManagement/enterTemplateToReportUnit',
        component: () =>
          import(
            '@publicSystems/views/reportSheetManagement/enterTemplateToReportUnit/index.vue'
          ),
        name: 'enterTemplateToReportUnit',
        meta: {
          title: '输入模板对照到报告单元',
        }
      },

      //#endregion

      //#region 申请项目管理
      {
        path: '/publicSystems/applyProjectManagement/subOrdersClass',
        component: () => import('@publicSystems/views/applyProjectManagement/subOrdersClass/Index.vue'),
        name: 'applyProjectManagementSubOrdersClass',
        meta: {
          title: '分单类别',
        }
      },
      {
        path: '/publicSystems/applyProjectManagement/applyProject',
        component: () => import('@publicSystems/views/applyProjectManagement/applyProject/Index.vue'),
        name: 'applyProjectManagementApplyProject',
        meta: {
          title: '申请项目',
        }
      },
      {
        path: '/publicSystems/applyProjectManagement/subOrdersClassCompareApplyProject',
        component: () =>
          import('@publicSystems/views/applyProjectManagement/subOrdersClassCompareApplyProject/Index.vue'),
        name: 'applyProjectManagementSubOrdersClassCompareApplyProject',
        meta: {
          title: '分单类别对照申请项目',
        }
      },
      {
        path: '/publicSystems/applyProjectManagement/specimenType',
        component: () => import('@publicSystems/views/applyProjectManagement/specimenType/Index.vue'),
        name: 'applyProjectManagementSpecimenType',
        meta: {
          title: '标本类型',
        }
      },
      {
        path: '/publicSystems/applyProjectManagement/applyProjectCompareReportItems',
        component: () => import('@publicSystems/views/applyProjectManagement/applyProjectCompareReportItems/Index.vue'),
        name: 'applyProjectManagementApplyProjectCompareReportItems',
        meta: {
          title: '申请项目对照报告项目',
        }
      },
      //#endregion

      //#region 检验仪器管理
      {
        path: '/publicSystems/inspectionInstrumentManagement/inspectionInstruments',
        component: () => import('@publicSystems/views/inspectionInstrumentManagement/inspectionInstruments.vue'),
        name: 'inspectionInstrumentManagementInspectionInstruments',
        meta: {
          title: '检验仪器',
        }
      },
      {
        path: '/publicSystems/inspectionInstrumentManagement/instrumentChannelNumberComparisonItem',
        component: () => import('@publicSystems/views/inspectionInstrumentManagement/instrumentChannelNumberComparisonItem/Index.vue'),
        name: 'inspectionInstrumentManagementInstrumentChannelNumberComparisonItem',
        meta: {
          title: '仪器通道号项目对照',
        }
      },
      {
        path: '/publicSystems/inspectionInstrumentManagement/instrumentRawResultsSecondaryProcessing',
        component: () => import('@publicSystems/views/inspectionInstrumentManagement/instrumentRawResultsSecondaryProcessing/Index.vue'),
        name: 'inspectionInstrumentManagementInstrumentRawResultsSecondaryProcessing',
        meta: {
          title: '仪器原始结果二次处理',
        }
      },

      //#endregion
    ]
  }
];

export default publicSystemsRouter;