/*
 * @Author: zzh
 * @Date: 2022-03-25 17:22:45
 * @LastEditors: zzh
 * @LastEditTime: 2023-03-29 13:58:44
 * @Description: 检验工作站路由
 * @FilePath: \hwason-laboratory-systems\src\router\inspectionWorkstation.ts
 */
import { RouteRecordRaw } from 'vue-router';
// import VerticalLayout from '@/layout/verticalLayout/Index.vue';
import HorizontalLayout from '@/layout/horizontalLayout/Index.vue';

const inspectionWorkstationRouter: Array<RouteRecordRaw> = [
  {
    path: '/inspectionWorkstation',
    component: HorizontalLayout,
    redirect: '/inspectionWorkstation/dashboard',
    name: 'inspectionWorkstation',
    meta: {
      title: '工作检验站系统',
    },
    children: [
      {
        path: 'dashboard',
        component: () =>
          import('@inspectionWorkstation/views/dashboard/Index.vue'),
        name: 'inspectionWorkstationDashboard',
        meta: {
          title: 'dashboard',
        }
      },
      {
        path: '/inspectionWorkstation/clinicalExamination/workPageMicrobe',
        component: () => import('@inspectionWorkstation/views/clinicalExamination/workPageMicrobe.vue'),
        name: 'workPageMicrobe',
        meta: {
          title: '工作页(微)',
        }
      },
      {
        path: '/preProcessing/outwardDelivery/outwardInventory',
        component: () =>
          import('@preProcessing/views/outwardDelivery/outwardInventory/index.vue'),
        name: 'outwardInventory',
        meta: {
          title: '外送清单',
        }
      },
      {
        path: '/preProcessing/outwardDelivery/sampleRegistration',
        component: () =>
          import('@preProcessing/views/outwardDelivery/sampleRegistration/index.vue'),
        name: 'sampleRegistration',
        meta: {
          title: '样本外送登记',
        }
      },
      {
        path: '/preProcessing/basicSettings/singleBarcodePrefabrication',
        component: () =>
          import(
            '@preProcessing/views/basicSettings/singleBarcodePrefabrication.vue'
          ),
        name: 'singleBarcodePrefabrication',
        meta: {
          title: '单条码预制',
        }
      },
      {
        path: '/preProcessing/basicSettings/barcodeManagement',
        component: () =>
          import(
            '@preProcessing/views/basicSettings/barcodeManagement.vue'
          ),
        name: 'barcodeManagement',
        meta: {
          title: '条码管理',
        }
      },
      {
        path: '/preProcessing/queryStatistics/comprehensiveQuery',
        component: () =>
          import('@preProcessing/views/queryStatistics/comprehensiveQuery/Index.vue'),
        name: 'preProcessingComprehensiveQuery',
        meta: {
          title: '综合查询',
        }
      },
      {
        path: '/preProcessing/queryStatistics/sampleSizeStatistics',
        component: () =>
          import('@preProcessing/views/queryStatistics/sampleSizeStatistics/index.vue'),
        name: 'sampleSizeStatistics',
        meta: {
          title: '标本量统计',
        }
      },
      //#region 临检 - 工作页
      {
        path: '/inspectionWorkstation/clinicalExamination/workPage',
        component: () => import('@inspectionWorkstation/views/clinicalExamination/workPage.vue'),
        name: 'workPage',
        meta: {
          title: '工作页(通)',
        }
      },
      {
        path: '/inspectionWorkstation/clinicalExamination/clinicalExaminationBloodTypeReport',
        component: () => import('@inspectionWorkstation/views/clinicalExamination/clinicalExaminationBloodTypeReport.vue'),
        name: 'clinicalExaminationBloodTypeReport',
        meta: {
          title: '临检血液血型报表',
        }
      },
      {
        path: '/inspectionWorkstation/clinicalExamination/bloodRoutineReviewRecordReport',
        component: () => import('@inspectionWorkstation/views/clinicalExamination/bloodRoutineReviewRecordReport.vue'),
        name: 'bloodRoutineReviewRecordReport',
        meta: {
          title: '血常规复查记录表',
        }
      },
      {
        path: '/inspectionWorkstation/clinicalExamination/bulkResultEntry',
        component: () => import('@inspectionWorkstation/views/clinicalExamination/bulkResultEntry.vue'),
        name: 'bulkResultEntry',
        meta: {
          title: '批量结果录入',
        }
      },
      {
        path: '/inspectionWorkstation/clinicalExamination/querySummaryTable',
        component: () => import('@inspectionWorkstation/views/clinicalExamination/querySummaryTable.vue'),
        name: 'querySummaryTable',
        meta: {
          title: '查询汇总表',
        }
      },
      {
        path: '/inspectionWorkstation/clinicalExamination/inspectionCombinationTable',
        component: () => import('@inspectionWorkstation/views/clinicalExamination/inspectionCombinationTable.vue'),
        name: 'inspectionCombinationTable',
        meta: {
          title: '检验组合表格',
        }
      },
      //#endregion

      //#region 临检 - 基因
      {
        path: '/inspectionWorkstation/gene/reportWorkPage',
        component: () => import('@inspectionWorkstation/views/gene/reportWorkPage/Index.vue'),
        name: 'inspectionWorkstationReportWorkPage',
        meta: {
          title: '报告工作页',
        }
      },
      {
        path: '/inspectionWorkstation/gene/specimenCollection',
        component: () => import('@inspectionWorkstation/views/gene/specimenCollection/Index.vue'),
        name: 'inspectionWorkstationSpecimenCollection',
        meta: {
          title: '标本核收',
        }
      },
      {
        path: '/inspectionWorkstation/gene/circulationCollection',
        component: () => import('@inspectionWorkstation/views/gene/circulationCollection/Index.vue'),
        name: 'inspectionWorkstationCirculationCollection',
        meta: {
          title: '流转核收',
        }
      },
      {
        path: '/inspectionWorkstation/gene/batchReview',
        component: () => import('@inspectionWorkstation/views/gene/batchReview/Index.vue'),
        name: 'inspectionWorkstationBatchReview',
        meta: {
          title: '批量审核',
        }
      },
      {
        path: '/inspectionWorkstation/gene/scanAndCatalogue',
        component: () => import('@inspectionWorkstation/views/gene/scanAndCatalogue/index.vue'),
        name: 'scanAndCatalogue',
        meta: {
          title: '扫码录单',
        }
      },
      {
        path: '/inspectionWorkstation/gene/nucleicAcidReport',
        component: () => import('@inspectionWorkstation/views/gene/nucleicAcidReport/index.vue'),
        name: 'nucleicAcidReport',
        meta: {
          title: '全民核酸报表',
        }
      },
      {
        path: '/inspectionWorkstation/gene/applicationFormEntry',
        component: () => import('@inspectionWorkstation/views/gene/applicationFormEntry/Index.vue'),
        name: 'inspectionWorkstationApplicationFormEntry',
        meta: {
          title: '申请单录入',
        }
      },
      //#endregion
      {
        path: '/inspectionWorkstation/abnormalHandoff/abnormalHandoff',
        component: () =>
          import(
            '@preProcessing/views/abnormalHandoff/abnormalHandoff.vue'
          ),
        name: 'abnormalHandoff1',
        meta: {
          title: '异常交接',
        }
      },
      //#region 临检 - 后处理
      {
        path: '/inspectionWorkstation/afterProceess/specimenRackType',
        component: () => import('@inspectionWorkstation/views/afterProceess/specimenRackType/index.vue'),
        name: 'specimenRackType',
        meta: {
          title: '标本架类型',
        }
      },
      {
        path: '/inspectionWorkstation/afterProceess/sampleLibraryConfiguration',
        component: () => import('@inspectionWorkstation/views/afterProceess/sampleLibraryConfiguration/Index.vue'),
        name: 'sampleLibraryConfiguration',
        meta: {
          title: '样本库位表',
        }
      },
      {
        path: '/inspectionWorkstation/afterProceess/specimenRackMaintenance',
        component: () => import('@inspectionWorkstation/views/afterProceess/specimenRackMaintenance/index.vue'),
        name: 'specimenRackMaintenance',
        meta: {
          title: '标本架维护',
        }
      },
      {
        path: '/inspectionWorkstation/afterProceess/sampleSavingSettings',
        component: () => import('@inspectionWorkstation/views/afterProceess/sampleSavingSettings/index.vue'),
        name: 'sampleSavingSettings',
        meta: {
          title: '样本保存时间设置',
        }
      },
      {
        path: '/inspectionWorkstation/afterProceess/specimenWarehousing',
        component: () => import('@inspectionWorkstation/views/afterProceess/specimenWarehousing/index.vue'),
        name: 'specimenWarehousing',
        meta: {
          title: '标本入库',
        }
      },
      {
        path: '/inspectionWorkstation/afterProceess/specimenExWarehouse',
        component: () => import('@inspectionWorkstation/views/afterProceess/specimenExWarehouse/index.vue'),
        name: 'specimenExWarehouse',
        meta: {
          title: '标本出库',
        }
      },
      {
        path: '/inspectionWorkstation/afterProceess/specimenReturn',
        component: () => import('@inspectionWorkstation/views/afterProceess/specimenReturn/index.vue'),
        name: 'specimenReturn',
        meta: {
          title: '标本归还',
        }
      },
      {
        path: '/inspectionWorkstation/afterProceess/specimenDestruction',
        component: () => import('@inspectionWorkstation/views/afterProceess/specimenDestruction/index.vue'),
        name: 'specimenDestruction',
        meta: {
          title: '标本销毁',
        }
      },
      {
        path: '/inspectionWorkstation/afterProceess/specimenInquiry',
        component: () => import('@inspectionWorkstation/views/afterProceess/specimenInquiry/index.vue'),
        name: 'specimenInquiry',
        meta: {
          title: '标本查询',
        }
      },
      {
        path: '/inspectionWorkstation/afterProceess/operationLog',
        component: () => import('@inspectionWorkstation/views/afterProceess/operationLog/index.vue'),
        name: 'operationLog',
        meta: {
          title: '操作日志',
        }
      },
      //#endregion

      //#region 临检--微生物
      {
        path: '/inspectionWorkstation/microbiological/consumableManagement',
        component: () => import('@inspectionWorkstation/views/microbiological/consumableManagement/index.vue'),
        name: 'consumableManagement',
        meta: {
          title: '耗材管理',
        }
      },
      {
        path: '/inspectionWorkstation/microbiological/consumableInventory',
        component: () => import('@inspectionWorkstation/views/microbiological/consumableInventory/index.vue'),
        name: 'consumableInventory',
        meta: {
          title: '入库管理',
        }
      },
      {
        path: '/inspectionWorkstation/microbiological/consumablesOutbound',
        component: () => import('@inspectionWorkstation/views/microbiological/consumablesOutbound/index.vue'),
        name: 'consumablesOutbound',
        meta: {
          title: '出库管理',
        }
      },
      {
        path: '/inspectionWorkstation/microbiological/antibioticDictionary',
        component: () => import('@inspectionWorkstation/views/microbiological/antibioticDictionary/index.vue'),
        name: 'antibioticDictionary',
        meta: {
          title: '抗生素字典',
        }
      },
      {
        path: '/inspectionWorkstation/microbiological/bacteriaClassification',
        component: () => import('@inspectionWorkstation/views/microbiological/bacteriaClassification/index.vue'),
        name: 'bacteriaClassification',
        meta: {
          title: '细菌分类',
        }
      },
      {
        path: '/inspectionWorkstation/microbiological/antibioticGroup',
        component: () => import('@inspectionWorkstation/views/microbiological/antibioticGroup/index.vue'),
        name: 'antibioticGroup',
        meta: {
          title: '抗生素组',
        }
      },
      {
        path: '/inspectionWorkstation/microbiological/commonBacterialCount',
        component: () => import('@inspectionWorkstation/views/microbiological/commonBacterialCount/index.vue'),
        name: 'commonBacterialCount',
        meta: {
          title: '常见细菌计数',
        }
      },
      {
        path: '/inspectionWorkstation/microbiological/drugResistance',
        component: () => import('@inspectionWorkstation/views/microbiological/drugResistance/index.vue'),
        name: 'drugResistance',
        meta: {
          title: '耐药机制',
        }
      },
      {
        path: '/inspectionWorkstation/microbiological/specimenApplicationSummary',
        component: () => import('@inspectionWorkstation/views/microbiological/specimenApplicationSummary/index.vue'),
        name: 'specimenApplicationSummary',
        meta: {
          title: '标本申请项目汇总表',
        }
      },
      //#endregion

      //#region 酶免专业组
      {
        path: '/inspectionWorkstation/enzymeImmunoassay/enzymeMarkerItemSetting',
        component: () => import('@inspectionWorkstation/views/enzymeImmunoassay/enzymeMarkerItemSetting/index.vue'),
        name: 'enzymeMarkerItemSetting',
        meta: {
          title: '酶标项目设置',
        }
      },
      {
        path: '/inspectionWorkstation/enzymeImmunoassay/plateMicroplateReader',
        component: () => import('@inspectionWorkstation/views/enzymeImmunoassay/plateMicroplateReader/index.vue'),
        name: 'plateMicroplateReader',
        meta: {
          title: '酶标仪布板',
        }
      },
      {
        path: '/inspectionWorkstation/enzymeImmunoassay/incubationProcessRecord',
        component: () => import('@inspectionWorkstation/views/enzymeImmunoassay/incubationProcessRecord/index.vue'),
        name: 'incubationProcessRecord',
        meta: {
          title: '温育过程记录',
        }
      },
      {
        path: '/inspectionWorkstation/enzymeImmunoassay/microplateReader',
        component: () => import('@inspectionWorkstation/views/enzymeImmunoassay/microplateReader/index.vue'),
        name: 'microplateReader',
        meta: {
          title: '酶标仪读板',
        }
      },
      {
        path: '/inspectionWorkstation/enzymeImmunoassay/microplateReader/record',
        component: () =>
          import(
            '@inspectionWorkstation/views/enzymeImmunoassay/microplateReader/record/Index.vue'
          ),
        name: 'microplateReaderRecord',
        meta: {
          title: '读板记录',
          fatherPath: '/inspectionWorkstation/enzymeImmunoassay/microplateReader'
        },
      }, // 酶标仪读板-读板记录
      {
        path: '/inspectionWorkstation/enzymeImmunoassay/projectDefaultReader',
        component: () => import('@inspectionWorkstation/views/enzymeImmunoassay/projectDefaultReader/index.vue'),
        name: 'projectDefaultReader',
        meta: {
          title: '酶标仪项目默认布板',
        }
      },
      // 报告查询
      {
        path: '/inspectionWorkstation/reportSearch/reportSearch',
        component: () => import('@inspectionWorkstation/views/reportSearch/index.vue'),
        name: 'inspectionWorkstationReportSearch',
        meta: {
          title: '报告查询',
        }
      },
      //#endregion
    ]
  }
];

export default inspectionWorkstationRouter;