<!--
 * @Author: zzh
 * @Date: 2022-02-25 09:55:19
 * @LastEditors: zzh
 * @LastEditTime: 2022-11-30 15:32:33
 * @Description: 侧边栏
 * @FilePath: \hwason-laboratory-systems\src\layout\verticalLayout\components\side_bar\Index.vue
-->
<template>
  <div :class="{ 'has-logo': showLogo }" class="sideWrap">
    <SidebarLogo v-if="showLogo" :collapse="collapse" />
    <el-scrollbar class="scrollbar" wrap-class="scrollbar-wrapper">
      <el-menu class="menu el-menu-vertical" router :collapse="!collapse" :collapse-transition="false">
        <SidebarItem v-for="route in menuList && menuList.children" :key="route.id" :item="route"></SidebarItem>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script setup lang="ts">
import SidebarLogo from './SidebarLogo.vue';
import SidebarItem from './SidebarItem.vue';
import TagsView from '../tags_view/Index.vue';
import { storeToRefs } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useLayoutStore } from '../../../../stores/layout';
import { convertMenuArrToTree } from '../../../../utils/dataConvert';
import { MenuNode } from '../../../../model/menuNode';

const store = useLayoutStore();
const { collapse } = storeToRefs(store);

let menuList = ref([] as any);

const getMenuData = async () => {
  // 刷新的时候，menuList会被清除
  if (store.allMenuList)
    // menuList = convertMenuArrToTree(store.menuList);
    menuList.value = store.allMenuList.find(
      (x: any) => x.permsionName === store.systemName
    );
};

getMenuData();
// console.log(menuList);

const router = useRouter();
const showLogo = ref(true);
const defaultSelectTab = computed(() => {
  return router.currentRoute.value.path;
});

const handleOpen = (key: string, keyPath: string[]) => {
  // console.log(key, keyPath, "open");
};

const handleClose = (key: string, keyPath: string[]) => {
  // console.log(key, keyPath);
};
</script>


<style lang="scss" scoped>
// .el-scrollbar {
//   height: 100%;
// }

.has-logo {
  .scrollbar {
    height: calc(100vh - 50px);

    &:deep(.el-scrollbar__view) {
      height: 100%;
    }

    .el-menu-vertical {
      height: 100%;
      border-right: 0px;
      // background: linear-gradient(to bottom, #7234c3, #496be7);
      background: #fff;
    }
  }
}
</style>
