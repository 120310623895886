<template>
    <div class="camera-dialog" style="cursor: grab;">
        <div class="camera-dialog-body">
            <!-- <video id="camera" width="350" height="350" autoplay style="object-fit: fill;"></video> -->
            <video id="camera" autoplay style=" width: 100%; height: 100%; object-fit: fill;"></video>
            <canvas id="canvas" height="350" width="350" style="display: none;"></canvas>
        </div>
        <div class="camera-dialog-foot">
            <el-row class="row" :gutter="15">
                <el-col class="column left" :span="18">
                    <div class="info">{{ info }}</div>
                    <div class="buttons">
                        <el-button link type="danger" :text="true" @click="closeCamera" v-if="isCameraStart">关闭</el-button>
                        <el-button link type="success" :text="true" @click="openCamera" v-else>开启</el-button>
                        <el-button link type="primary" :text="true" @click="clickLast">上一个</el-button>
                        <el-button link type="primary" :text="true" @click="clickNext">下一个</el-button>
                    </div>
                </el-col>
                <el-col class="column right" :span="6">
                    <el-button class="btn-save" @click="saveImage">保存<br />图片</el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import CameraModal from './index';
import VideoHelper from '@/utils/videoHelper';
import { storeToRefs } from 'pinia';
import { useCameraStore } from '@/stores/camera';

const store = useCameraStore();

const { selectedRow } = storeToRefs(store);

let cameraModal: any = null;
// 添加弹窗可拖拽的监听
onMounted(() => {
    cameraModal = new CameraModal();
    cameraModal.addWindowResizeListener();
    cameraModal.addDialogMousedownListener();
    cameraModal.addDialogMousemoveListener();
    cameraModal.addDialogMouseupListener();
});

const info = computed(() => {
    if (selectedRow.value && selectedRow.value.patientName) {
        return `${selectedRow.value.sampleId} ${selectedRow.value.patientName} (${selectedRow.value.patientAge}岁)`;
    } else {
        return '暂无信息！！！';
    }
});

// 摄像头
const isCameraStart = ref(true);
const videoInstance = new VideoHelper();
onMounted(async () => {
    await videoInstance.start();
    videoInstance.showInVideo('camera');
});

const openCamera = async () => {
    await videoInstance.start();
    videoInstance.showInVideo('camera');
    isCameraStart.value = true;
};

const closeCamera = () => {
    videoInstance.close();
    isCameraStart.value = false;
};

const clickLast = () => {
    store.setMoveTag(-1);
};

const clickNext = () => {
    store.setMoveTag(1);
};

const saveImage = () => {
    videoInstance.drawCanvas('camera', 'canvas');
    const imageBase64 = videoInstance.convertCanvasToImage('canvas');
    store.setImageBase64(imageBase64);
};

</script>

<style lang="scss" scoped>
@import './index.scss';
</style>