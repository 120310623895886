let instance: VideoHelper | null = null;

export default class VideoHelper {
    stream: any;
    constructor() {
        this.stream = null;
    }
    static getInstance() {
        if (!instance) {
            instance = new VideoHelper();
        }
        return instance;
    }

    async start() {
        const navigatorT: any = navigator;
        navigatorT.getUserMedia = navigatorT.getUserMedia ||
            navigatorT.webkitGetUserMedia ||
            navigatorT.mozGetUserMedia ||
            navigatorT.msGetUserMedia; //获取媒体对象（这里指摄像头）
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            this.stream = stream;
        } catch (error: any) {
            alert('启用摄像头失败：' + error);
        }
    }

    close() {
        this.stream.getTracks().forEach(function (track: any) {
            track.stop();
        });

        const windowT: any = window;
        windowT.mediaStreamTrack && windowT.mediaStreamTrack.stop();
    }

    showInVideo(domId: string) {
        const videoDom: any = document.getElementById(domId);
        if (videoDom) videoDom.srcObject = this.stream;

        videoDom.onerror = function () {
            this.close();
        };
    }

    drawCanvas(videoDomId: string, canvasDomId: string,width:Number=350,height:Number=350) {
        const canvasDom: any = document.getElementById(canvasDomId);
        const videoDom: any = document.getElementById(videoDomId);
        const ctx = canvasDom.getContext('2d');
        ctx.drawImage(videoDom, 0, 0, width, height); //将获取视频绘制在画布上
    }

    convertCanvasToImage(canvasDomId: any) {
        const canvasDom: any = document.getElementById(canvasDomId);
        const image = new Image();
        image.src = canvasDom.toDataURL('image/png');
        return image.src;
    }
}