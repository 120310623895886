/*
 * @Author: zzh
 * @Date: 2022-03-16 15:37:57
 * @LastEditors: JHH
 * @LastEditTime: 2023-05-16 10:16:14
 * @Description: 前处理系统路由
 * @FilePath: \hwason-laboratory-systems\src\router\preProcessing.ts
 */
import { RouteRecordRaw } from 'vue-router';
import HorizontalLayout from '@/layout/horizontalLayout/Index.vue';

const preProcessingRouter: Array<RouteRecordRaw> = [
  {
    path: '/preProcessing',
    component: HorizontalLayout,
    redirect: '/preProcessing/dashboard',
    name: 'preProcessing',
    meta: {
      title: '前处理',
    },
    children: [
      {
        path: 'dashboard',
        component: () =>
          import('@preProcessing/views/dashboard/Index.vue'),
        name: 'preProcessingDashboard',
        meta: {
          title: '前处理首页',
        }
      },

      //#region 样本签收
      {
        path: 'sampleAcceptance/sampleAcceptance',
        component: () =>
          import('@preProcessing/views/sampleAcceptance/sampleAcceptance/Index.vue'),
        name: 'preProcessingSampleAcceptance',
        meta: {
          title: '样本签收',
        }
      },
      //#endregion

      //#region 分血
      {
        path: '/preProcessing/bloodDivision/fractionalBloodInBatches',
        component: () =>
          import('@preProcessing/views/bloodDivision/fractionalBloodInBatches.vue'),
        name: 'fractionalBloodInBatches',
        meta: {
          title: '批量分血',
        }
      },
      {
        path: '/preProcessing/bloodDivision/individualBloodDivision',
        component: () =>
          import('@preProcessing/views/bloodDivision/individualBloodDivision.vue'),
        name: 'individualBloodDivision',
        meta: {
          title: '单个分血',
        }
      },
      //#endregion

      // #region 分拣与交接
      {
        path: '/preProcessing/sortingAndHandover/sortingMachineMaintain',
        component: () =>
          import('@preProcessing/views/sortingAndHandover/sortingMachineMaintain/index.vue'),
        name: 'sortingMachineMaintain',
        meta: {
          title: '分拣仪维护',
        }
      },
      {
        path: '/preProcessing/sortingAndHandover/conventionalSorting',
        component: () =>
          import('@preProcessing/views/sortingAndHandover/conventionalSorting/index.vue'),
        name: 'conventionalSorting',
        meta: {
          title: '常规分拣',
        }
      },
      {
        path: '/preProcessing/sortingAndHandover/circulationSorting',
        component: () =>
          import('@preProcessing/views/sortingAndHandover/circulationSorting/index.vue'),
        name: 'circulationSorting',
        meta: {
          title: '流转分拣',
        }
      },
      {
        path: '/preProcessing/sortingAndHandover/professionalGroupHandover',
        component: () =>
          import('@preProcessing/views/sortingAndHandover/professionalGroupHandover/Index.vue'),
        name: 'professionalGroupHandover',
        meta: {
          title: '专业组交接',
        }
      },
      {
        path: '/preProcessing/sortingAndHandover/handoverQuery',
        component: () =>
          import('@preProcessing/views/sortingAndHandover/handoverQuery/Index.vue'),
        name: 'handoverQuery',
        meta: {
          title: '交接查询',
        }
      },
      // #endregion

      //#region 基础设置
      {
        path: '/preProcessing/basicSettings/departmentManagement',
        component: () =>
          import(
            '@preProcessing/views/basicSettings/departmentManagement.vue'
          ),
        name: 'departmentManagement',
        meta: {
          title: '分拣分血规则',
        }
      },
      {
        path: '/preProcessing/basicSettings/inspectionCenterFeeItemsMaintenance',
        component: () =>
          import(
            '@preProcessing/views/basicSettings/inspectionCenterFeeItemsMaintenance.vue'
          ),
        name: 'inspectionCenterFeeItemsMaintenance',
        meta: {
          title: '检验中心收费项目维护',
        }
      },
      {
        path: '/preProcessing/basicSettings/testTubeRackMaintenance',
        component: () =>
          import(
            '@preProcessing/views/basicSettings/testTubeRackMaintenance.vue'
          ),
        name: 'testTubeRackMaintenance',
        meta: {
          title: '试管架维护',
        }
      },
      {
        path: '/preProcessing/basicSettings/singleBarcodePrefabrication',
        component: () =>
          import(
            '@preProcessing/views/basicSettings/singleBarcodePrefabrication.vue'
          ),
        name: 'singleBarcodePrefabrication',
        meta: {
          title: '单条码预制',
        }
      },
      {
        path: '/preProcessing/basicSettings/barcodeManagement',
        component: () =>
          import(
            '@preProcessing/views/basicSettings/barcodeManagement.vue'
          ),
        name: 'barcodeManagement',
        meta: {
          title: '条码管理',
        }
      },
      {
        path: '/preProcessing/basicSettings/outwardHos',
        component: () =>
          import('@preProcessing/views/basicSettings/outwardHos/index.vue'),
        name: 'outwardHos',
        meta: {
          title: '外送机构',
        }
      },
      // {
      //   path: '/preProcessing/basicSettings/dictionaryClassification',
      //   component: () =>
      //     import(
      //       '@preProcessing/views/basicSettings/dictionaryClassification.vue'
      //     ),
      //   name: 'dictionaryClassification',
      //   meta: {
      //     title: '字典分类',
      //   }
      // },
      // {
      //   path: '/preProcessing/basicSettings/dictionaryData',
      //   component: () =>
      //     import(
      //       '@preProcessing/views/basicSettings/dictionaryData.vue'
      //     ),
      //   name: 'dictionaryData',
      //   meta: {
      //     title: '字典数据',
      //   }
      // },
      //#endregion

      //#region 异常交接
      {
        path: '/preProcessing/abnormalHandoff/abnormalHandoff',
        component: () =>
          import(
            '@preProcessing/views/abnormalHandoff/abnormalHandoff.vue'
          ),
        name: 'abnormalHandoff',
        meta: {
          title: '异常交接',
        }
      },
      // #endregion
      // #region 专业组
      {
        path: '/preProcessing/professionalGroup/secondarySorting',
        component: () =>
          import('@preProcessing/views/professionalGroup/secondarySorting/index.vue'),
        name: 'secondarySorting',
        meta: {
          title: '二次分拣',
        }
      },
      {
        path: '/preProcessing/professionalGroup/sortingConfirm',
        component: () =>
          import('@preProcessing/views/professionalGroup/sortingConfirm/index.vue'),
        name: 'sortingConfirm',
        meta: {
          title: '分拣确认',
        }
      },
      // #endregion
      //#region 查询统计
      {
        path: 'queryStatistics/comprehensiveQuery',
        component: () =>
          import('@preProcessing/views/queryStatistics/comprehensiveQuery/Index.vue'),
        name: 'preProcessingComprehensiveQuery',
        meta: {
          title: '综合查询',
        }
      },
      {
        path: '/queryStatistics/abnormalHandoverQuery',
        component: () =>
          import('@preProcessing/views/queryStatistics/abnormalHandoverQuery/index.vue'),
        name: 'abnormalHandoverQuery',
        meta: {
          title: '异常交接查询',
        }
      },
      {
        path: '/queryStatistics/sampleMonitoring',
        component: () =>
          import('@preProcessing/views/queryStatistics/sampleMonitoring/index.vue'),
        name: 'preProcessingPreProcessSpecimenMonitoring',
        meta: {
          title: '前处理标本监控',
        }
      },
      {
        path: '/queryStatistics/sampleSizeStatistics',
        component: () =>
          import('@preProcessing/views/queryStatistics/sampleSizeStatistics/index.vue'),
        name: 'sampleSizeStatistics',
        meta: {
          title: '标本量统计',
        }
      },
      {
        path: 'queryStatistics/newCoronavirusStatisticsReport',
        component: () =>
          import('@preProcessing/views/queryStatistics/newCoronavirusStatisticsReport/index.vue'),
        name: 'preProcessingPreProcessNewCoronavirusStatisticsReport',
        meta: {
          title: '新冠统计报表',
        }
      },
      {
        path: 'queryStatistics/newCoronavirusUploadReport',
        component: () =>
          import('@preProcessing/views/queryStatistics/newCoronavirusUploadReport/index.vue'),
        name: 'preProcessingPreProcessNewCoronavirusUploadReport',
        meta: {
          title: '新冠上报报表',
        }
      },
      //#endregion

      //#region 条码录入
      {
        path: 'printBarcodeEntry/localPrintSettings',
        component: () =>
          import('@preProcessing/views/printBarcodeEntry/localPrintSettings.vue'),
        name: 'preProcessingLocalPrintSettings',
        meta: {
          title: '本地打印设置',
        }
      },
      {
        path: 'printBarcodeEntry/printParamSetting',
        component: () => import('@preProcessing/views/printBarcodeEntry/printParamSetting/Index.vue'),
        name: 'preProcessingprintParamSetting',
        meta: {
          title: '打印参数配置',
        }
      },
      {
        path: 'printBarcodeEntry/inspectionApplicationManagement',
        component: () =>
          import('@preProcessing/views/printBarcodeEntry/inspectionApplicationManagement/Index.vue'),
        name: 'preProcessingInspectionApplicationManagement',
        meta: {
          title: '检验申请管理',
        }
      },
      //#endregion
      // #region 客服管理
      {
        path: '/customerService/editPatientInfo',
        component: () =>
          import('@preProcessing/views/customerService/editPatientInfo/index.vue'),
        name: 'preProcessingEditPatientInfo',
        meta: {
          title: '病人信息修改',
        }
      },
      // #endregion 客服管理
      
      //#region 外送     
      {
        path: '/outwardDelivery/tubeRackRegistration',
        component: () =>
          import('@preProcessing/views/outwardDelivery/tubeRackRegistration/index.vue'),
        name: 'tubeRackRegistration',
        meta: {
          title: '试管架外送登记',
        }
      },
      {
        path: '/outwardDelivery/sampleRegistration',
        component: () =>
          import('@preProcessing/views/outwardDelivery/sampleRegistration/index.vue'),
        name: 'sampleRegistration',
        meta: {
          title: '样本外送登记',
        }
      },
      {
        path: '/outwardDelivery/outwardInventory',
        component: () =>
          import('@preProcessing/views/outwardDelivery/outwardInventory/index.vue'),
        name: 'outwardInventory',
        meta: {
          title: '外送清单',
        }
      },
      //#endregion
    ]
  }
];

export default preProcessingRouter;