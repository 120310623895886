<template>
  <div class="app-main">
        <router-view v-slot="{ Component }">
      <keep-alive :include="cachedViews && cachedViews.map((x: any) => x.name)">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script lang="ts" setup>
import { useLayoutStore } from '../../../stores';
import { storeToRefs } from 'pinia';
const store = useLayoutStore();

const { cachedViews } = storeToRefs(store);
</script>

<style lang="scss" scoped>
.app-main {
  padding: 10px;
  height: calc(100% - 85px);
  width: 100%;
  background-color: rgba(255,255,255, 0.1);
  &:deep(.el-scrollbar__view) {
    height: 100%;
  }

  // &:deep(.el-scrollbar__bar.is-horizontal) {
  //   display: none !important;
  // }
}
</style>
