import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';

// vex-table
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
// element plus
import moment from 'moment';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/index.css';
import * as Icons from '@element-plus/icons-vue';
import './styles/global.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
// 其他组件库
import axios from 'axios';
// 覆盖样式
import './assets/iconfont/iconfont.css';
import './styles/element-override.css';
import './styles/iconfont-override.css';

import App from './App.vue';
import router from './router';

const app = createApp(App);
const store = createPinia();

store.use(createPersistedState({
    storage: sessionStorage,
    // beforeRestore: () => {},
    // afterRestore: () => {},
    serializer: {
      serialize: JSON.stringify,
      deserialize: JSON.parse,
    }
  }));

app.config.globalProperties.$http = axios;
app.config.globalProperties.$moment = moment;

// 注册Icons 全局组件，element plus icon
Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key]);
});

app.use(VXETable);
app.use(store);

app.use(router);
app.use(ElementPlus, { locale });

app.mount('#app');
