<!--
 * @Author: zzh
 * @Date: 2022-02-25 09:07:01
 * @LastEditors: zzh
 * @LastEditTime: 2022-11-30 16:05:23
 * @Description: 布局入口
 * @FilePath: \hwason-laboratory-systems\src\layout\horizontalLayout\Index.vue
-->
<template>
  <div class="layout">
    <NavigationBar />
    <TagsView />
    <AppMain />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { NavigationBar, AppMain, TagsView } from './components';

onMounted(() => {
  console.log();
});
</script>


<style lang="scss" scoped>

</style>
