export default class CameraModal {
  dialogDom: any;
  mouseX: number;
  mouseY: number;
  dialogX: number;
  dialogY: number;
  isDraging: boolean;
  dialogBodyDom: any;
  constructor() {
    this.dialogDom = document.querySelector('.camera-dialog');
    this.dialogBodyDom = document.querySelector('.camera-dialog-body');
    this.mouseX = 0; // 鼠标x,y轴坐标
    this.mouseY = 0;
    this.dialogX = 0; // 弹窗x,y轴坐标
    this.dialogY = 0;
    this.isDraging = false;
  }

  fixedModal() {
    this.dialogDom.style.right = '100px';
    this.dialogDom.style.top = '50px';
  }


  addWindowResizeListener() {
    window.onresize = () => this.fixedModal();
  }

  addDialogMousedownListener() {
    this.dialogBodyDom.addEventListener('mousedown', (e: any) => {
      this.mouseX = e.pageX;
      this.mouseY = e.pageY;
      this.dialogX = this.dialogDom.offsetLeft;
      this.dialogY = this.dialogDom.offsetTop;
      this.isDraging = true;
    });
    const resizeObserver = new ResizeObserver(this.onResize);
    resizeObserver.observe(this.dialogDom);
  }

  onResize(entries:any) {
    // this.dialogDom.style.width = entries[0].target.width;
    // this.dialogDom.style.height = entries[0].target.height;
  }

  addDialogMousemoveListener() {
    this.dialogBodyDom.addEventListener('mousemove', (e: any) => {
      const mx = e.pageX; //移动时鼠标X坐标
      const my = e.pageY; //移动时鼠标y坐标
      if (this.isDraging) {
        let moveX = mx - this.mouseX + this.dialogX; //位移值+弹窗left值=移动后弹窗left值
        let moveY = my - this.mouseY + this.dialogY; //移动后弹窗top值

        //限制弹窗拖拽范围
        //页面宽高
        const pageW = document.documentElement.clientWidth;
        const pageH = document.documentElement.clientHeight;
        //弹窗宽高
        const dialogW = this.dialogDom.offsetWidth;
        const dialogH = this.dialogDom.offsetHeight;
        const maxX = pageW - dialogW; //x轴可拖动最大值
        const maxY = pageH - dialogH; //x轴可拖动最大值
        //Math.min()  Math.max()妙用
        moveX = Math.min(Math.max(0, moveX), maxX); //设定left范围, 0-maxX
        moveY = Math.min(Math.max(0, moveY), maxY); //设定top范围, 0-maxY
        //重新设置弹窗位置
        this.dialogDom.style.left = moveX + 'px';
        this.dialogDom.style.top = moveY + 'px';
      }
    });
  }

  addDialogMouseupListener() {
    this.dialogBodyDom.addEventListener('mouseup', () => this.isDraging = false);
  }

}
