/*
 * @Author: zzh
 * @Date: 2022-03-22 10:30:34
 * @LastEditors: zzh
 * @LastEditTime: 2022-07-12 08:52:49
 * @Description: 在多个公共子系统中的公共方法，UI相关
 * @FilePath: \hwason-laboratory-systems\src\utils\systemCommon.ts
 */
import router from '../router';

export const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    router.push('/');
    location.reload();
};
