/*
 * @Author: zzh
 * @Date: 2022-03-03 10:26:35
 * @LastEditors: JHH
 * @LastEditTime: 2022-11-30 11:11:17
 * @Description: 病理系统路由
 * @FilePath: \hwason-laboratory-systems\src\router\pathologicalSystem.ts
 */
import { RouteRecordRaw } from 'vue-router';
// import VerticalLayout from '@/layout/verticalLayout/Index.vue';
import VerticalLayout from '@/layout/verticalLayout/Index.vue';

const pathologicalSystemRouter: Array<RouteRecordRaw> = [
  {
    path: '/pathologicalSystem',
    component: VerticalLayout,
    redirect: '/pathologicalSystem/dashboard',
    name: 'pathologicalSystem',
    meta: {
      title: '病理系统',
    },
    children: [
      //#region 通用
      {
        path: 'dashboard',
        component: () =>
          import('@pathologicalSystem/views/dashboard/Index.vue'),
        name: 'pathologicalSystemDashboard',
        meta: {
          title: '首页',
        },
      },
      {
        path: '404',
        component: () =>
          import(
            /* webpackChunkName: "pathologicalSystem_404" */ '@/views/404.vue'
          ),
        meta: {
          title: '404',
        },
      },
      //#region 异常交接
      {
        path: '/pathologicalSystem/abnormalHandoff/abnormalHandoff',
        component: () =>
          import(
            '@preProcessing/views/abnormalHandoff/abnormalHandoff.vue'
          ),
        name: 'pathologicalSystemAbnormalHandoff',
        meta: {
          title: '异常交接',
        }
      },
      // #endregion
      //#endregion

      //#region 基础数据
      {
        path: '/pathologicalSystem/basicData/reportingUnitsCommonDescriptions',
        component: () =>
          import(
            '@pathologicalSystem/views/basicData/reportingUnitsCommonDescriptions/Index.vue'
          ),
        name: 'pathologicalSystemReportingUnitsCommonDescriptions',
        meta: {
          title: '报告单元常见描述',
        },
      },
      {
        path: '/pathologicalSystem/basicData/pathologicalDiagnosisMaintenance',
        component: () =>
          import(
            '@pathologicalSystem/views/basicData/pathologicalDiagnosisMaintenance/Index.vue'
          ),
        name: 'pathologicalSystemPathologicalDiagnosisMaintenance',
        meta: {
          title: '病理诊断维护',
        },
      },
      {
        path: '/pathologicalSystem/basicData/abnormalHandoff',
        component: () =>
          import(
            '@pathologicalSystem/views/basicData/abnormalHandoff/index.vue'
          ),
        name: 'pathologiAbnormalHandoff',
        meta: {
          title: '异常处理',
        },
      },
      //#endregion
      //#region 系统管理
      {
        path: '/pathologicalSystem/systemAdministration/nodeMaintenance',
        component: () =>
          import(
            '@pathologicalSystem/views/systemAdministration/nodeMaintenance/Index.vue'
          ),
        name: 'pathologicalSystemNodeMaintenance',
        meta: {
          title: '节点维护',
        },
      },
      {
        path: '/pathologicalSystem/systemAdministration/processMaintenance',
        component: () =>
          import(
            '@pathologicalSystem/views/systemAdministration/processMaintenance/Index.vue'
          ),
        name: 'pathologicalSystemProcessMaintenance',
        meta: {
          title: '流程维护',
        }
      },
      {
        path: '/pathologicalSystem/systemAdministration/processMaintenance/processSubpage',
        component: () =>
          import(
            '@pathologicalSystem/views/systemAdministration/processMaintenance/processSubpage/Index.vue'
          ),
        name: 'pathologicalSystemProcessSubpage',
        meta: {
          title: '流程',
          fatherPath: '/pathologicalSystem/systemAdministration/processMaintenance'
        },
      }, // 流程维护-流程子页面
      {
        path: '/pathologicalSystem/systemAdministration/processMaintenance/applicationItems',
        component: () =>
          import(
            '@pathologicalSystem/views/systemAdministration/processMaintenance/applicationItems/Index.vue'
          ),
        name: 'pathologicalSystemApplicationItems',
        meta: {
          title: '申请项目',
          fatherPath: '/pathologicalSystem/systemAdministration/processMaintenance'
        },
      }, // 流程维护-申请项目子页面
      //#endregion
      //#region 样本库管理
      {
        path: '/pathologicalSystem/sampleLibraryManagement/sampleLibraryConfiguration',
        component: () => import('@pathologicalSystem/views/sampleLibraryManagement/sampleLibraryConfiguration/Index.vue'),
        name: 'pathologicalSampleConfiguration',
        meta: {
          title: '样本库配置',
        },
      },
      {
        path: '/pathologicalSystem/sampleLibraryManagement/specimenWarehousing',
        component: () => import('@pathologicalSystem/views/sampleLibraryManagement/specimenWarehousing/index.vue'),
        name: 'pathologicalSpecimenWarehousing',
        meta: {
          title: '样本库管理',
        },
      },
      //#endregion
      //#region 病理库管理
      {
        path: '/pathologicalSystem/pathologyLibraryManagement/caseBank',
        component: () =>
          import(
            '@pathologicalSystem/views/pathologyLibraryManagement/caseBank/Index.vue'
          ),
        name: 'pathologicalCaseBank',
        meta: {
          title: '病例库',
        },
      },
      //#endregion
      //#region 样本前处理
      {
        path: '/pathologicalSystem/samplePretreatment/sampleSigning',
        component: () =>
          import(
            '@pathologicalSystem/views/samplePretreatment/sampleSigning/Index.vue'
          ),
        name: 'pathologicalSampleSigning',
        meta: {
          title: '样本签收',
        },
      },
      //#endregion
      //#region 制片工作站
      {
        path: '/pathologicalSystem/productionWorkstations/pathologicalProductionManagement',
        component: () =>
          import(
            '@pathologicalSystem/views/productionWorkstations/pathologicalProductionManagement/Index.vue'
          ),
        name: 'pathologicalProductionManagement',
        meta: {
          title: '病理制片管理',
        },
      },
      //#endregion
      //#region 查询统计
      {
        path: '/pathologicalSystem/queryStatistics/applicationFormInquiry',
        component: () =>
          import(
            '@pathologicalSystem/views/queryStatistics/applicationFormInquiry/Index.vue'
          ),
        name: 'pathologicalSystemApplicationFormInquiry',
        meta: {
          title: '申请单查询',
        },
      },
      {
        path: '/pathologicalSystem/queryStatistics/pathologyReportInquiry',
        component: () =>
          import(
            '@pathologicalSystem/views/queryStatistics/pathologyReportInquiry/Index.vue'
          ),
        name: 'pathologicalSystemPathologyReportInquiry',
        meta: {
          title: '病理报告查询',
        },
      },
      {
        path: '/pathologicalSystem/queryStatistics/positiveRateStatisticalQuery',
        component: () =>
          import(
            '@pathologicalSystem/views/queryStatistics/positiveRateStatisticalQuery/Index.vue'
          ),
        name: 'pathologicalSystemPositiveRateStatisticalQuery',
        meta: {
          title: '阳性率统计查询',
        },
      },
      //#endregion
      //#region 报告工作站
      {
        path: '/pathologicalSystem/reportWorkstation/histopathology',
        component: () =>
          import(
            '@pathologicalSystem/views/reportWorkstation/histopathology/Index.vue'
          ),
        name: 'pathologicalSystemHistopathology',
        meta: {
          title: '组织病理',
        },
      },
      {
        path: '/pathologicalSystem/reportWorkstation/cervicalTwoCarcinomaTCT',
        component: () =>
          import(
            '@pathologicalSystem/views/reportWorkstation/cervicalTwoCarcinomaTCT/Index.vue'
          ),
        name: 'pathologicalSystemCervicalTwoCarcinomaTCT',
        meta: {
          title: '宫颈/两癌TCT',
        },
      },
      {
        path: '/pathologicalSystem/reportWorkstation/papSmearNonGynecological',
        component: () =>
          import(
            '@pathologicalSystem/views/reportWorkstation/papSmearNonGynecological/Index.vue'
          ),
        name: 'pathologicalSystemPapSmearNonGynecological',
        meta: {
          title: '宫颈刮片/非妇科',
        },
      },
      {
        path: '/pathologicalSystem/reportWorkstation/immunohistochemistry',
        component: () =>
          import(
            '@pathologicalSystem/views/reportWorkstation/immunohistochemistry/Index.vue'
          ),
        name: 'pathologicalSystemImmunohistochemistry',
        meta: {
          title: '免疫组化',
        },
      },
      {
        path: '/pathologicalSystem/reportWorkstation/microorganism',
        component: () =>
          import(
            '@pathologicalSystem/views/reportWorkstation/microorganism/Index.vue'
          ),
        name: 'pathologicalSystemMicroorganism',
        meta: {
          title: '微生物',
        },
      },
      {
        path: '/pathologicalSystem/reportWorkstation/dnaPolyploidy',
        component: () =>
          import(
            '@pathologicalSystem/views/reportWorkstation/dnaPolyploidy/Index.vue'
          ),
        name: 'pathologicalDnaPolyploidy',
        meta: {
          title: 'DNA倍体',
        },
      },
      //#endregion

      //#region 病理复片
      {
        path: '/pathologicalSystem/pathologicalRelay/histopathologicalReimplation',
        component: () =>
          import(
            '@pathologicalSystem/views/pathologicalRelay/histopathologicalReimplation/Index.vue'
          ),
        name: 'pathologicalSystemHistopathologicalReimplation',
        meta: {
          title: '组织病理复片',
        },
      },
      {
        path: '/pathologicalSystem/pathologicalRelay/cervicalTwoCarcinomaTCTReimplantment',
        component: () =>
          import(
            '@pathologicalSystem/views/pathologicalRelay/cervicalTwoCarcinomaTCTReimplantment/Index.vue'
          ),
        name: 'pathologicalSystemCervicalTwoCarcinomaTCTReimplantment',
        meta: {
          title: '宫颈/两癌TCT复片',
        },
      },
      //#endregion
      {
        // 匹配所有路径  vue2使用*   vue3使用/:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
        path: '/:pathMatch(.*)',
        redirect: '/pathologicalSystem/404',
        meta: { hidden: true },
      },
    ],
  },
];

export default pathologicalSystemRouter;
