<!--
 * @Author: zzh
 * @Date: 2022-02-25 09:07:01
 * @LastEditors: zzh
 * @LastEditTime: 2022-06-23 15:02:38
 * @Description: 布局入口
 * @FilePath: \hwason-laboratory-systems\src\layout\verticalLayout\Index.vue
-->
<template>
  <div class="layout">
    <div :class="collapse ? 'left' : 'left-fold'">
      <Sidebar />
    </div>

    <div class="right">
      <div class="right-header">
        <Navbar />
        <TagsView />
      </div>
      <AppMain />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Sidebar, Navbar, AppMain, TagsView } from './components';
import { useLayoutStore } from '../../stores';
import { storeToRefs } from 'pinia';

const store = useLayoutStore();
const { collapse } = storeToRefs(store);
</script>


<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  display: flex;
  .left {
    min-width: 220px;
  }
  .left-fold {
    width: 70px;
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    .right-header {
      height: 90px;
    }
  }

  &:deep(.el-menu-item.is-active) {
    color: #409eff;
  } 
}
</style>
