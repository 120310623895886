import { defineStore } from 'pinia';

export const useCameraStore = defineStore({
  id: 'camera',
  state: () => ({
    currentRouteName: {} as any,
    selectedRow: {} as any,
    searchFormModel: {} as any,
    moveTag: 0, // 0：不移动，-1： 上一个，1：下一个
    imageBase64: '',
    relevancyResultTotal: 0, // 关联结果的统计数量
  }),

  persist: true,
  
  getters: {
    // currentRouteName: (state) => state.currentRoute.name,
  },
  
  actions: {
    setCurrentRouteName(routeName: any):void {
      this.currentRouteName = routeName;
    },

    setSelectedRow (row:any):void {
      this.selectedRow = row;
    },

    setSearchFormModel (formModel:any) {
      this.searchFormModel = formModel;
    },

    setMoveTag (tag:number):void {
      this.moveTag = tag;
    },

    setImageBase64 (imageBase64:string) {
      this.imageBase64 = imageBase64;
    },

    setRelevancyResultTotal (num:number) {
      this.relevancyResultTotal = num;
    },
  }
});
