<template>
  <!-- <button @click="printCommonMessageTest">测试按钮</button> -->
  <router-view />
</template>

<script setup lang="ts">
import { ExecException } from 'child_process';
import { onMounted, nextTick, ref, onUnmounted } from 'vue';
import storage from './utils/storage';
import WebSocketClass from './utils/websocket';
import Img from './assets/img/foldIcon.png';
import { popSuccessMessage } from './components/hs-message';
const ENV = import.meta.env;
onMounted(async () => { storage.setIsCloud(ENV.VITE_ENVIRONMENT === 'cloud'); });

const registration = ref();

const printCommonMessageTest = (e: any) => {
  sendMessageToWorkerJS({
    type: 'notification',
    message: {
      title: '异常确认提醒',
      options: {
        body: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
        renotify: true, // 允许覆盖
        tag: '20230327-' + (new Date()).toISOString(),
        icon: Img,
        // image: Img,
        badge: Img,
        actions: [
          {
            action: 'go',
            title: '去处理',
            icon: Img
          }
        ]
      }
    }
  });

};


onMounted(async () => {
  // registSocket(); // socket连接
  // await registWorker(); // 注册sw
  // await askNotificationPermission(); // 请求权限
});

onUnmounted(() => {
  registration.value.unregister();
  navigator.serviceWorker.removeEventListener('message', receiveWorkerJSMessage);
});

const receiveWorkerJSMessage = (event: MessageEvent<any>) => {
  // console.log(`The service worker sent me a message: ${event.data}`);
  popSuccessMessage(`The service worker sent me a message: ${event.data}`);
};

interface TMessage {
  type: string
  message: { [x: string]: any }
  [x: string]: any
}

const sendMessageToWorkerJS = (message: TMessage) => {
  const token = storage.getToken();
  if (!token) return;
  registration.value.active.postMessage(message); // post message to service workerjs
};

const registWorker = async () => {
  if (!('serviceWorker' in navigator)) return;
  registration.value = await navigator.serviceWorker.register('/sw.js');
  // reigster onmessage event
  navigator.serviceWorker.addEventListener('message', receiveWorkerJSMessage);
};

// 请求notification授权
const askNotificationPermission = async () => {
  if (typeof Notification === 'undefined' || Notification.permission === 'granted' || Notification.permission === 'denied') return;
  const permission = await Notification.requestPermission();
  switch (permission) {
    case 'granted':
      // eslint-disable-next-line no-case-declarations
      const notification = new Notification('恭喜，你已经开启的桌面提供功能~');
      break;
    case 'denied':
      console.log('Notification 权限已被禁用');
      break;
    default:
      console.log('Notification 权限尚未授权');

  }
};

// 模拟真实场景，一般这种web桌面提示，触发时机都是后端利用socket传递消息过来的
const registSocket = () => {
  try {
    const ws = new WebSocketClass('ws://127.0.0.1:1809', null);
    setInterval(() => { if (!ws.isConnection) ws.reconnection(); }, 3000); // 防止中断后无相应
  } catch (e) {
    console.log('连接socket失败: ' + e);
  }

};

</script>
